<template>
  <div
    class="Head flex justify-between align-center"
    v-if="path !== '/mobileApproval'"
  >
    <template
      v-if="path === '/performanceDetails' || path === '/performancePlan'"
    >
      <template v-if="isSuperior">
        <div class="flex align-center">
          <i
            class="el-icon-arrow-left padding cursor-pt"
            style="font-size: 20px"
            @click="onRouterApprove"
          />
          <span
            >{{ route?.query?.month }}月绩效{{
              Number(route?.query?.planState) > 4 ? "月总结" : "计划"
            }}</span
          >
        </div>
      </template>
      <template v-else>
        <div class="flex align-center">
          <div class="Head-img flex align-center justify-center">
            <img
              v-if="store.state.user?.avatar?.length !== 0"
              :src="store.state.user.avatar"
            />
            <span v-else> {{ store.state.user?.name?.slice(-2) }}</span>
          </div>
          <p class="Head-name margin-left">{{ store.state.user.name }}</p>
          <p class="Head-position">
            {{ store.state.user.departmentName }} ——
            {{ store.state.user.position }}
          </p>
        </div>
        <div class="Head-right flex align-center">
          <el-tabs v-model="editableTabsValue" @tab-click="onRouter">
            <el-tab-pane
              v-for="item in monthMonthplanList"
              :key="item.id"
              :label="item.month"
              :name="`${item.id}`"
            />
          </el-tabs>
          <!-- v-if="userId === '083660176336335363' || userId ==='101441163724114368'"-->
          <div
            class="Head-management flex"
            v-if="
              userId === '083660176336335363' || userId === '101441163724114368'
            "
          >
            <div
              class="flex align-center cursor-pt"
              @click="onRouterManagement"
            >
              <i class="icon approve" />
              <span class="Head-right-text">后台管理</span>
            </div>
          </div>
          <div
            class="Head-approve flex"
            v-if="store.state.user.isAdmin || userId === '083660176336335363'"
          >
            <div class="flex align-center cursor-pt" @click="onRouterApprove">
              <i class="icon approve" />
              <span class="Head-right-text">审批</span>
            </div>
          </div>
          <div class="Head-login flex" v-if="userId === '083660176336335363'">
            <div class="flex align-center cursor-pt" @click="switchLogin">
              <i class="icon approve" />
              <span class="Head-right-text">切换登录</span>
            </div>
          </div>
        </div>
      </template>
    </template>
    <template v-if="path === '/approvalManagement'">
      <div class="Head-left-text">月绩效计划审批</div>
      <div
        class="flex align-center cursor-pt"
        @click="() => getMonthMonthplanList()"
      >
        <i class="icon plan" />
        <span class="Head-right-text">个人绩效计划</span>
      </div>
    </template>
    <template v-if="path === '/backStageManagement'">
      <div class="Head-left-text">月绩效计划后台管理</div>
      <div
        class="flex align-center cursor-pt"
        @click="() => getMonthMonthplanList()"
      >
        <i class="icon plan" />
        <span class="Head-right-text">个人绩效计划</span>
      </div>
    </template>
  </div>
</template>

<script>
import { onMounted, ref, watchEffect } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import * as dd from 'dingtalk-jsapi'
import { dindinLogin, selectAllPlan } from '@/api/user'
import { useStore } from '@/vuex/store'

export default {
  name: 'Head',
  setup () {
    const editableTabsValue = ref('1')
    const path = ref('/')
    const isSuperior = ref(false)
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const userId = ref('')
    const monthMonthplanList = ref([])
    // 标签页跳转
    const onRouter = ({ index }) => {
      let path = '/performanceDetails'
      if (
        route.path === '/mobileApproval' ||
        (route.path === '/performanceDetails' && route.query.isSuperior)
      ) {
        return
      }
      if (
        monthMonthplanList.value[index].planState === 1 ||
        monthMonthplanList.value[index].planState === 3
      ) {
        path = '/performancePlan'
      }
      router.push({
        path,
        query: {
          planState: monthMonthplanList.value[index].planState,
          id: monthMonthplanList.value[index].id,
          month: monthMonthplanList.value[index].month
        }
      })
      // router.push({
      //   path: monthMonthplanList.value[index].path
      // })
    }
    // 跳转后台管理页面
    const onRouterManagement = () => {
      console.log(router)
      router.push({
        path: '/backStageManagement',
        query: {
          id: route.query.id
        }
      })
    }
    // 获取月计划列表
    const getMonthMonthplanList = (userid) => {
      if (route.query.isSuperior) {
        return
      }
      selectAllPlan({
        userid: userid || JSON.parse(sessionStorage.getItem('user')).userid
      }).then((res) => {
        console.log(res.data)
        if (res.code === 200) {
          monthMonthplanList.value = res.data
          editableTabsValue.value = `${res.data[res.data.length - 1].id}`
          onRouter({ index: res.data.length - 1 })
        }
      })
    }
    // 获取用户信息
    const getUser = (id) => {
      if (!sessionStorage.getItem('user')) {
        if (dd.env.platform !== 'notInDingTalk') {
          dd.ready(function () {
            // dd.ready参数为回调函数，在环境准备就绪时触发，jsapi的调用需要保证在该回调函数触发后调用，否则无效。
            dd.runtime.permission.requestAuthCode({
              corpId: 'ding8e9662bf765c28c5',
              onSuccess: function (result) {
                dindinLogin({
                  code: result.code,
                  userId: id || ''
                }).then((res) => {
                  if (res.code === 200) {
                    sessionStorage.setItem('user', JSON.stringify(res.data))
                    store.action.updateUser(res.data)
                    userId.value = res.data?.userid
                    getMonthMonthplanList(res.data.userid)
                  }
                })
              },
              onFail: function (err) {
                console.log(err)
              }
            })
          })
        }
      } else {
        store.action.updateUser(JSON.parse(sessionStorage.getItem('user')))
        getMonthMonthplanList()
      }
    }
    // 切換登錄
    const switchLogin = () => {
      sessionStorage.removeItem('user')
      getUser('0333266228557604405')
    }
    // 跳转审批管理
    const onRouterApprove = () => {
      router.push({
        path: '/approvalManagement'
      })
    }
    watchEffect(() => {
      if (route.query.isSuperior) {
        isSuperior.value = true
      } else {
        isSuperior.value = false
      }
    })
    watchEffect(() => {
      if (route.path) {
        path.value = route.path
      }
    })
    watchEffect(() => {
      if (store.state.isSubmit) {
        getMonthMonthplanList()
        store.action.updateIsSubmit(false)
      }
    })
    onMounted(() => {
      getUser()
    })
    return {
      monthMonthplanList,
      editableTabsValue,
      router,
      onRouter,
      onRouterApprove,
      path,
      store,
      getMonthMonthplanList,
      isSuperior,
      route,
      userId,
      onRouterManagement,
      switchLogin
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.Head {
  padding: 0px 24px;
  height: 80px;
  color: #262626;
  background: #fff;

  &-img {
    height: 52px;
    width: 52px;
    border-radius: 8px;
    background: #3296fa;

    img {
      border-radius: 8px;
      width: 100%;
      height: 100%;
    }

    span {
      color: #fff;
    }
  }

  &-name {
    font-size: 20px;
    margin-right: 40px;
  }

  &-position {
    font-size: 24px;
  }

  &-left-text {
    color: rgba(0, 0, 0, 0.85);
    font-size: 20px;
  }

  &-right-text {
    font-size: 20px;
    color: #1890ff;
    margin-left: 6px;
  }

  &-approve {
    width: 104px;
    height: 100%;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    margin-left: 24px;
    padding-left: 22px;
  }
  &-login {
    width: 125px;
    height: 100%;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    margin-left: 24px;
    padding-left: 22px;
  }
  &-management {
    width: 130px;
    height: 100%;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    margin-left: 24px;
    padding-left: 22px;
  }
}

.Head-right::v-deep {
  height: 100%;

  .el-tabs {
    max-width: 286px;
    height: 52px;
    //display: flex;
    //align-content: center;
    .el-tabs__header {
      margin: 0;
      padding-top: 5px;
    }

    .el-tabs__active-bar {
      display: none;
    }

    .is-active,
    .el-tabs__item:hover {
      background: #1890ff;
      color: #fff;
    }

    .el-tabs__item {
      padding: 0 16px !important;
    }

    .el-tabs__nav-next,
    .el-tabs__nav-prev {
      line-height: 42px;
      font-size: 24px;
      color: #121212;
      font-weight: 600;
    }

    .el-tabs__nav-prev {
      left: 16px;
    }

    .el-tabs__nav-next {
      right: 16px;
    }

    .el-tabs__nav-scroll {
      border-left: 1px solid #ebebeb;
      border-right: 1px solid #ebebeb;
    }

    .el-tabs__nav-wrap {
      padding: 0 56px;
      border: 1px solid #ebebeb;
      border-radius: 8px;

      &:after {
        background: #fff;
      }
    }
  }
}
</style>
