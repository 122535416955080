import requests from '@/requests'
// 获取用户信息
export function dindinLogin (data) {
  return requests(
    '/monthplan/enterprise/dindinLogin',
    'get',
    data
  )
}
// 查询当前用户的所有绩效月计划list
export function selectAllPlan (data) {
  return requests(
    '/monthplan/month/plan/selectAllPlan',
    'get',
    data
  )
}
// 查询当前用户的所有绩效月计划list2
export function planList (data) {
  return requests(
    `monthplan/month/plan/list?userId=${data.userId}`,
    'get'
  )
}
//  数据来源
export function dataSource (data) {
  return requests(
    '/monthplan/enterprise/dataSource',
    'get',
    data
  )
}
//  提交月计划,保存计划项
export function submitMonthPlan (data) {
  return requests(
    '/monthplan/month/plan/submitMonthPlan',
    'post',
    data
  )
}
//   离焦点保存
export function saveMonthPlan (data) {
  return requests(
    '/monthplan/month/plan/saveMonthPlan',
    'post',
    data
  )
}
//   删除目标项
export function deleteItem (data) {
  return requests(
    '/monthplan/plan/item/deleteItem',
    'get',
    data
  )
}
//  获取详情
export function selectMonthPlan (data) {
  return requests(
    '/monthplan/month/plan/selectMonthPlan',
    'get',
    data
  )
}
//  添加自我评价、备注、自我打分(复评开始)
export function addSelfAssess (data) {
  return requests(
    '/monthplan/month/plan/addSelfAssess',
    'post',
    data
  )
}
// 搜索时主管或是领导查看审批列表
export function selectFlowList (data) {
  return requests(
    '/monthplan/flowList/select',
    'get',
    data
  )
}
// 主管或是领导查看审批列表
export function selectList (data) {
  return requests(
    '/monthplan/flowList/selectFlowList',
    'get',
    data
  )
}
// 主管或是领导查看月计划详情
export function seeDetail (data) {
  return requests(
    '/monthplan/flowList/seeDetail',
    'get',
    data
  )
}
// 审批人员list
export function personList (data) {
  return requests(
    '/monthplan/approval/personList',
    'get',
    data
  )
}
// 更改任务状态,添加拒绝理由
export function updatePlanState (data) {
  return requests(
    `/monthplan/month/plan/updatePlanState?approvePersonId=${data.approvePersonId}&planId=${data.planId}&checkResult=${data.checkResult}&remark=${data.remark}`,
    'post'
  )
}
//  新增会审评价
export function jointSave (data) {
  return requests(
    '/monthplan/joint/save',
    'post',
    data
  )
}
//  新增上级评价
export function updateMonthPlan (data) {
  return requests(
    '/monthplan/review/addReview',
    'post',
    data
  )
}
//  撤销提交
export function withdraw (data) {
  return requests(
    '/monthplan/plan/item/withdraw',
    'get',
    data
  )
}
//  再次提交
export function submitAgain (data) {
  return requests(
    '/monthplan/plan/item/submitAgain',
    'get',
    data
  )
}
// 获取oss临时上传权限
export function getOssCode (data) {
  return requests(
    '/monthplan/oss/getOssCode',
    'post',
    data
  )
}
// 查询员工状态列表
export function staffList (data) {
  return requests(
    `/monthplan/user/viewMonthPlan?current=${data.current}&pageSize=${data.pageSize}&userName=${data.userName}&departmentId=${data.departmentId}&planState=${data.planState}`,
    'get'
  )
}
// 获取部门列表
export function department (data) {
  return requests(
    '/monthplan/department/list',
    'get',
    data
  )
}
// 获取月绩效报表
export function performancePlan (data) {
  return requests(
    `/monthplan/month/plan/performancePlan?currPage=${data.currPage}&pageSize=${data.pageSize}&departmentId=${data.departmentId}&year=${data.year}&userName=${data.userName}`,
    'post'
  )
}
// 获取月绩效管理数据
export function viewMonthPlan (data) {
  return requests(
    `/monthplan/month/plan/viewMonthPlan?currPage=${data.currPage}&pageSize=${data.pageSize}&month=${data.month}&userName=${data.userName}&departmentId=${data.departmentId}&planState=${data.planState}`,
    'post'
  )
}
// 获取后台首页数据
export function homePage (data) {
  return requests(
    '/monthplan/user/homePage',
    'get',
    data
  )
}
// 查看绩效详情
export function viewInfo (data) {
  return requests(
    `/monthplan/month/plan/viewInfo?planId=${data.planId}`,
    'get'
  )
}
// 查看绩效汇总表详情
export function getInfo (data) {
  return requests(
    `/monthplan/month/plan/getInfo?planId=${data.planId}`,
    'get'
  )
}
// 表格导出
export function orderAllDataExcel (data) {
  return requests(
    `/monthplan/export/orderAllDataExcel?month=${data.month}&userid=${data.userid}`,
    'get',
    '',
    'blob'
  )
}
// 选择审批人列表
export function getAuthList (data) {
  return requests(
    `/monthplan/approval/getAuthList?personalType=${data.personalType}&userName=${data.userName}&departmentName=${data.departmentName}&departmentId=${data.departmentId}`,
    'get'
  )
}
// 添加审批计划
export function approvalSave (data) {
  return requests(
    `/monthplan/approval/save?userid=${data.userid}&userName=${data.userName}&departmentName=${data.departmentName}&departmentId=${data.departmentId}&approvalIdList=${data.approvalIdList}&type=${data.type}`,
    'post'
  )
}
// 提醒通知
export function sendNotice (data) {
  return requests(
    `/monthplan/time/sendNotice?month=${data.month}&userid=${data.userid}`,
    'get'
  )
}
// 时间设置列表
export function timeList (data) {
  return requests(
    `/monthplan/time/list?month=${data.month}`,
    'get'
  )
}
// 时间修改提交
export function updateTime (data) {
  return requests(
    `/monthplan/time/update?id=${data.id}&fillStartTime=${data.fillStartTime}&fillEndTime=${data.fillEndTime}&reviewStartTime=${data.reviewStartTime}&reviewEndTime=${data.reviewEndTime}`,
    'PUT'
  )
}
// 表格全部导出
export function orderAllDataExcel2 (data) {
  return requests(
    `/monthplan/export/orderAllDataExcel?month=${data.month}`,
    'get',
    '',
    'blob'
  )
}
// 获取会审人员列表
export function authList (data) {
  return requests(
    '/monthplan/user/get/authList',
    'get'
  )
}
// 删除会审人员
export function deleteAuth (data) {
  return requests(
    `/monthplan/user/delete/auth?id=${data.id}`,
    'DELETE'
  )
}
// 是否在岗
export function deleteUser (data) {
  return requests(
    `/monthplan/user/delete?userid=${data.useid}&workState=${data.workState}`,
    'post'
  )
}
// 更改状态
export function planUpdate (data) {
  return requests(
    `/monthplan/month/plan/update?planId=${data.planId}&planState=${data.planState}`,
    'post'
  )
}

// 获取员工列表
export function userGetList (data) {
  return requests(
    '/monthplan/user/getList',
    'get'
  )
}
// t提交会审人员
export function addAuth (data) {
  return requests(
    `/monthplan/user/add/auth?userName=${data.userName}&userId=${data.userId}&personalType=${data.personalType}`,
    'post'
  )
}

// t提交会审人员
export function managementGetList (data) {
  return requests(
    `/monthplan/management/getList?userName=${data.userName}&departmentName=${data.departmentName}&size=${data.size}&current=${data.current}`,
    'get'
  )
}
// t提交会审人员
export function managementDelete (data) {
  return requests(
    `/monthplan/management/delete?ids=${data.ids}`,
    'post'
  )
}
// 获取员工审批流程
// t提交会审人员
export function getInfoUserId (data) {
  return requests(
    `/monthplan/approval/getInfo?userId=${data.userId}`,
    'get'
  )
}

// 生成月计划
export function monthPlanSave (data) {
  return requests(
    `/monthplan/month/plan/save?userId=${data.userId}&month=${data.month}&userName=${data.userName}`,
    'post'
  )
}
