import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'dayjs/locale/zh-cn'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import App from './App.vue'
import router from './router'
// import VConsole from 'vconsole'
import 'element-plus/lib/theme-chalk/index.css'
import '@/assets/style/util.less'
import '@/assets/style/icon.less'
// new VConsole()
const app = createApp(App)
app.use(ElementPlus, { locale, size: 'small', zIndex: 3000 })
app.use(router)
app.mount('#app')
