function updateToken (state) {
  return (token) => {
    state.token = token
  }
}

function updateUser (state) {
  return (user) => {
    state.user = user
  }
}
function updateCode (state) {
  return (code) => {
    state.code = code
  }
}
function updateIsSubmit (state) {
  return (isSubmit) => {
    state.isSubmit = isSubmit
  }
}
function updateRemark (state) {
  return (remark) => {
    state.remark = remark
  }
}
function updateIsJoinAssessor (state) {
  return (isJoinAssessor) => {
    state.isJoinAssessor = isJoinAssessor
  }
}
function updateIsShow (state) {
  return (isShow) => {
    state.isShow = isShow
  }
}
function updateIsDetail (state) {
  return (isDetail) => {
    state.isDetail = isDetail
  }
}

/**
 * 创建Action
 * @param state
 */
export function createAction (state) {
  return {
    updateToken: updateToken(state),
    updateCode: updateCode(state),
    updateUser: updateUser(state),
    updateIsSubmit: updateIsSubmit(state),
    updateRemark: updateRemark(state),
    updateIsJoinAssessor: updateIsJoinAssessor(state),
    updateIsShow: updateIsShow(state),
    updateIsDetail: updateIsDetail(state)
  }
}
