import { readonly } from 'vue'
import { createAction } from './action'
import { createState } from './state'

const state = createState()
const action = createAction(state)

export const useStore = () => {
  const store = {
    state: readonly(state),
    action: readonly(action)
  }

  return store
}
// import { useStore } from '@/vuex/store'
// const store = useStore()
// // 访问state
// const store = useStore()
// store.state.code
//
// // 调用action
// const store = useStore()
// store.action.updateCode(123)
