import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/performancePlan',
    name: '绩效计划',
    component: () => import('../views/performancePlan')
  },
  {
    path: '/performanceDetails',
    name: '绩效详情',
    component: () => import('../views/performanceDetails')
  },
  {
    path: '/approvalManagement',
    name: '月绩效计划',
    component: () => import('../views/approvalManagement')
  },
  {
    path: '/mobileApproval',
    name: '月绩效计划审批',
    component: () => import('../views/mobileApproval')
  },
  {
    path: '/backStageManagement',
    name: '月绩效计划后台管理',
    component: () => import('../views/backStageManagement')
  },
  {
    path: '/home',
    name: '首页',
    component: () => import('../views/backStageManagement/components/home')
  },
  {
    path: '/monthlyPerformance',
    name: '员工管理',
    component: () => import('../views/backStageManagement/components/monthlyPerformance')
  },
  {
    path: '/staffManagement',
    name: '月绩效管理',
    component: () => import('../views/backStageManagement/components/staffManagement')
  },
  {
    path: '/startTimeSetting',
    name: '起始时间设置',
    component: () => import('../views/backStageManagement/components/startTimeSetting')
  },
  {
    path: '/summarySheet',
    name: '月绩效汇总表',
    component: () => import('../views/backStageManagement/components/summarySheet')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
// 添加判断方法
const isMobile = () => {
  const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag
}
router.beforeEach((to, from, next) => {
  if (isMobile() && to.path === '/performanceDetails') {
    next(
      {
        path: '/mobileApproval',
        query: to.query
      }
    )
  } else {
    next()
  }
})
export default router
