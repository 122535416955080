import axios from 'axios'
import { ElMessage } from 'element-plus'
// import { message } from 'antd'
// import {getSession} from '../utils/storage';
// 根据环境不同引入不同api地址

const service = axios.create({
  // http://121.43.156.149:9004/labour/companyTurnkey/pageList
  baseURL: process.env.NODE_ENV === 'production' ? 'https://task.mdfcar.com/' : 'http://192.168.0.9:8087/', // 规定页面中所有相对链接的基准URL, // url = base api url + request url
  // 'http://yin.iceiceread.com/v1/'
  // http://118.89.81.78:7001/
  // baseURL:'http://118.89.81.78:7001/',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 // request timeout
})
// request拦截器 request interceptor
service.interceptors.request.use(
  (config) => {
    // 不传递默认开启loading
    // if (!config.hideloading) {
    //   // loading
    //   // Toast.loading({
    //   //   forbidClick: true,
    //   // });
    // }

    // if (store.getters.token) {
    //   config.headers['X-Token'] = '';
    // }
    //   header = 'application/x-www-form-urlencoded'
    const copyConfig = config
    // if (sessionStorage.getItem('apiTocken')) {
    //     const manage = getSession('manage');
    //     copyConfig.headers.roleId = manage.roleId;
    // }
    copyConfig.headers = { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }

    if (copyConfig.method === 'post') {
      copyConfig.data = config.data
      copyConfig.headers = { 'Content-Type': 'application/json' }
    } else {
      copyConfig.params = copyConfig.data
    }
    // copyConfig.headers={'content-Type':'application/x-www-form-urlencoded'};
    return copyConfig
  },
  (error) => Promise.reject(error)
  // do something with request error

)
// respone拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data

    if (res.code >= 400) {
      ElMessage.error(res.msg)
    }
    return Promise.resolve(res)
  },
  (error) => {
    // Toast.clear();
    // message.error('断开连接')
    return Promise.reject(error)
  }
)

function requests (url, method, data, responseType = undefined) {
  return service({
    url,
    method,
    data,
    responseType
  })
}

export default requests
