import { reactive } from 'vue'

export const State = {
  code: 's',
  token: 's',
  user: {},
  isSubmit: false,
  remark: '',
  isJoinAssessor: 3,
  isShow: false,
  isDetail: false
}

export function createState () {
  return reactive(State)
}
